<script>
import Swal from "sweetalert2";

import Layout from "../../../layouts/main.vue";
import axios from "axios";

export default {
    components: {
        Layout,
    },
    data() {
        return {
            medicine: [],
        };
    },
    methods: {
        async getMedicine() {
            const response = await axios.get(
                "https://api.doctosoft.com/index.php/api/medicine/" + this.$route.params.id
            );
            this.medicine = response.data;
        },
        successmsg() {
            Swal.fire("Good job!", "Updated the details of medicine ", "success");
        },
        async updateMedicine() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("name", this.medicine.name);
            urlencoded.append("description", this.medicine.description);

            await axios.put(
                "https://api.doctosoft.com/index.php/api/medicine" + "/" + this.$route.params.id,
                urlencoded
            );
            this.successmsg()
            this.$router.push("/admin/medicine");
        },

        errormsg() {
            Swal.fire(
                "Invalid Form",
                "Please fix the errors and try again later",
                "error"
            );
        },
    },
    created() {
    this.getMedicine();

  },
};
</script>
<template>
    <Layout>
        <div class="back-btn">
            <div class="arrow">
                <button class="btn btn-light" @click="$router.push('/admin/medicine')"><i
                        class="mdi mdi-chevron-left"></i></button>
            </div>
            <div class="action-btn">
                <b-button class="btn-soft-info waves-effect waves-light"
                    @click="$router.push('/admin/medicine')">Cancel</b-button>
                <button class="btn  btn-md btn btn-primary" type="submit" @click="updateMedicine">Save</button>
            </div>
        </div>
        <b-row class="card-main">
            <b-col sm="6" xl="10">
                <b-card no-body>
                    <form class="needs-validation" @submit.prevent="updateMedicine">
                        <div class="mb-3">
                            <label for="name" class="form-label">Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="medicine.name" placeholder="Enter Name" />
                        </div>

                        <div class="mb-3">
                            <label for="description" class="form-label">Description <span
                                    class="text-danger">*</span></label>
                            <textarea rows="10" class="form-control" v-model="medicine.description"
                                placeholder="Enter Description" />
                        </div>

                    </form>
                </b-card>
            </b-col>
        </b-row>
</Layout></template>
